.empty-message {
  margin-top: 25%;
}

.search-bar {
  margin-top: 50px;
  margin-bottom: 25px;
}

.item-grid-list {
  width: 500;
  height: 450;
}

.account-name {
  text-align: left;
}

.item-title {
  text-align: left;
}

.item-profile-image {
  color: #fafafa !important;
  background-color: #5f99cf !important;
  width: 100px !important;
  height: 100px !important;
}

.item-main-image {
  width: 100%;
}

.book-button {
  float: right;
}

.item-header {
  margin-top: 50px;
}

.date-string {
  text-align: left;
}

.details-title {
  text-align: left;
}

.componet-title {
  text-align: left;
}

.action-buttons {
  padding: 50px;
  padding-top: 0px;
}

.user-description-avatar {
  margin: auto;
  width: 100px !important;
  height: 100px !important;
}

.mobile-buttons {
  display: none;
}

.left-button {
  float: left;
}

.right-button {
  float: right;
}

.details-container {
  overflow: auto;
}

@media screen and (max-width: 768px) {
  .hideable-section {
    display: none;
  }

  .mobile-buttons {
    display: block;
  }
}
