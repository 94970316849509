.drop-zone-container {
  width: 100%;
}

.drop-zone {
  width: 100%;
  height: 200px;
  border: 3px;
  border-color: #457AFB;
  border-style: solid;
  text-align: center;
  border-radius: 20px;
}

.dropzone-item {
  padding: 5px;
}

.drop-zone-remove-button {
  float: left;
}

.dropzone-progress {
  float: right;
  margin: 10px;
}

.dropzone-item-name {
  margin: 10px;
  float: left;
}

.dropzone-item-img {
  float: left;
}

.dropzone-list {
  list-style-type: none;
}
