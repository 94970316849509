.card-text {
  color: #000;
}

.card {
  width: 100%;
}

.add-page-card {
  height: 140px;
}

.card-container {
  padding: 10px;
}

.friend-text {
  margin-left: 20px;
}

@media screen and (max-width: 575px) {
  .card {
    max-width: 100%;
  }
}
