.card-text {
  color: #000;
}

.card {
  width: 100%;
}

.add-page-card {
  height: 140px;
}

.card-container {
  padding: 10px;
}

.invite-avatar {
  width: 80px;
}

.invite-avatar-img {
  width: 75px !important;
  height: 75px !important;
  color: #fafafa !important;
  background-color: #5f99cf !important;
}

@media screen and (max-width: 575px) {
  .card {
    max-width: 100%;
  }
}
