.App {
  text-align: center;
  background-color: #fbfbff;
  min-height: 100vh;
  color: #636373;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiContainer-root {
  margin-top: 64px;
  height: -webkit-calc(100% - 64px);
  height: -moz-calc(100% - 64px);
  height: calc(100% - 64px);
  height: calc(100% - 64px);
}

.content-container-menu-open {
  margin-left: 240px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.container-fluid {
  padding-top: 56px;
}

.container {
  padding-top: 56px;
}

.authenticated-container {
  padding-left: 135px;
}

.ant-notification {
  z-index: 1050 !important;
}

@media screen and (max-width: 1024px) {
  .authenticated-container {
    padding-left: 15px;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
