.permission-level {
  float: right;
}

.client-name {
  float: left;
}

.client-card {
  color: #000;
  text-align: left;
}

.account-id {
  float: left
}
