.add-icon-button {
  right: 50px !important;
  bottom: 50px !important;
  position: fixed !important;
}

@media screen and (max-width: 760px) {
  .add-icon-button {
    right: 10px !important;
    bottom: 10px !important;
    position: fixed !important;
  }
}
