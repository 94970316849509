.settings-button {
  width: 30px;
  height: 30px;
}

.MuiAppBar-colorPrimary {
  background-color: #fbfbff !important;
}

.navbar-brand {
  margin-left: 20px;
}

.nav-avatar-img {
  width: 30px !important;
  height: 30px !important;
}

.MuiTypography-root {
  color: #636373 !important;
}

.app-bar-shift {
  margin-left: 240px;
}
