.card-title {
  color: #000;
}

.account-image {
  width: 40px !important;
  height: 40px !important;
}

.test-box {
  min-width: 235px;
  height: 20vw;
  min-height: 235px;
  background-color: blue;
}

.item-image-hover {
  opacity: 0.5;
  transition: 0.3s;
}

.item-image-text {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.item-image {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: 585px;
  border-radius: 10px;
  cursor: pointer;
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.item-container {
  width: 400px;
  height: 400px;
}

.gallery-selection {
  margin-top: 50px;
}
