.sidebar-btn {
  background-color: transparent;
  border: none;
}

.sidebar-item {
  width: 100px;
  height: 115px;
  margin: 0 auto;
}

.sidebar-item > p {
  text-align: center;
  color: #636373;
  font-size: 15px;
}

.sidebar-item > button:focus {
  outline: none;
}

@media screen and (max-width: 760px) {
  .sidebar-btn {
    background-color: transparent;
    border: none;
    float: left;
    width: 100%;
  }

  .sidebar-item {
    width: 100%;
    height: 80px;
  }

  .sidebar-btn > p {
    padding-top: 20px;
    text-align: left;
    color: #636373;
    font-size: 15px;
  }

  .sidebar-btn > svg {
    float: left;
  }

  .sidebar-item > button:focus {
    outline: none;
  }
}
