.masonry {
	flex-direction: row;
	justify-content: center;
	align-content: stretch;
	width: 100%;
	margin: auto;
}

@media screen and (min-width: 1024px) {
	.masonry {
		display: flex;
	}
}

.column {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: stretch;
	flex-grow: 1;
}

.tile {
  position: relative;
  width: 100%;
  padding: 2px;
}

.tile:after {
  content: "";
  display: block;
}

.tile > * {
	box-shadow: 0 1px 1px 2px rgba(0,0,0, .15);
	border-radius: 10px;
  width: 100%;
}

/* .fill > img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%
}

.tile > div {
  box-shadow: 0 1px 1px 2px rgba(0,0,0, .15);
	border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden
} */
