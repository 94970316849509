.empty-message {
  margin-top: 25%;
}

.search-bar {
  margin-top: 50px;
  margin-bottom: 25px;
}

.item-grid-list {
  width: 500;
  height: 450;
}

.account-name {
  text-align: left;
}

.item-title {
  text-align: left;
}

.item-profile-image {
  color: #fafafa !important;
  background-color: #5f99cf !important;
  width: 100px !important;
  height: 100px !important;
}

.item-main-image {
  width: 100%;
}

.book-button {
  float: right;
}

.item-header {
  margin-top: 50px;
}
