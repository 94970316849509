.upload-button {
  float: right;
}

.media-section-title {
  float: left;
}

.media-droppable {
  margin-top: 50px;
}

.media-start-message {
  color: black;
}

.media-card {
  max-width: 25rem;
}

.media-selector-image {
  object-fit: contain;
  height: 200px;
}
